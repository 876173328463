const routes = {
  updateEmail: "/api/auth/update-email",
  verifyEmail: (email: string) => {
    const urlParams = new URLSearchParams({ email });

    return `/api/auth/verify-email?${urlParams.toString()}`;
  },
  updateWallet: "/api/auth/update-wallet",
  verifyWallet: "/api/auth/verify-wallet",
  accountCreation: (username: string) => `/api/auth/account-creation?username=${username}`,
};

export default { auth: routes };
