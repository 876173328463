import React, { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { NavigationContext } from "@features/navigation/hooks";
import DisplayIf from "@components/Conditionals/DisplayIf";
import SpinnerPage from "@components/spinners/SpinnerPage";

interface NavigationProviderProps {
  children: React.ReactNode | React.ReactNode[] | undefined;
}

const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();

  const navigate = async (callback) => {
    setIsNavigating(true);
    await callback(router);
    setIsNavigating(false);
  };

  const navigateTo = async (path: string) => {
    return navigate(async (r) => {
      await r.push(path);
    });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(() => {
    return {
      navigate,
      navigateTo,
    };
  }, []);

  return (
    <NavigationContext.Provider value={context}>
      <DisplayIf condition={() => isNavigating}>
        <div className="flex absolute w-full h-full z-40">
          <div className="absolute bg-black w-full h-full opacity-20" />
          <SpinnerPage />
        </div>
      </DisplayIf>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
