import { Spin } from "antd";
import React from "react";

const SpinnerFullPage: React.FC = () => {
  return (
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Spin />
    </div>
  );
};

export default SpinnerFullPage;
