// There is a similar file on the client side until we build the configuration endpoint.
// Location is: src/client/auth/AuthProvider.tsx
import { ResourcesConfig } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";

export const AwsAmplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      allowGuestAccess: false,
      identityPoolId: "us-east-2:cdd25bda-bd21-4c74-8d77-3827b8e3f8f5",
      userPoolClientId: "4jtptt8rtmhort8gs4mp0tf0l2",
      userPoolId: "us-east-2_IxDq4MaVV",
      loginWith: {
        oauth: {
          redirectSignIn: ["https://engage-admin.metacommerce.app/"],
          redirectSignOut: ["https://engage-admin.metacommerce.app/"],
          domain: "metacommerce-idp-prod-01.metacommerce.app",
          responseType: "code",
          scopes: ["openid", "email", "profile"],
        },
      },
      signUpVerificationMethod: "code",
    },
  },
  Storage: {
    S3: {
      bucket: "assets.metacommerce.app",
      region: "us-east-2",
    },
  },
};

export const cookieStorageConfig = (): CookieStorage => {
  return new CookieStorage({
    domain: ".metacommerce.app",
    expires: 365,
    path: "/",
    sameSite: "strict",
    secure: true,
  });
};
