import React from "react";
import { FeaturesConfiguration } from "@components/Features/types";
import { FeaturesContext } from "@components/Features/contexts";
import featuresConfig from "@src/features";


type Props = {
  configuration?: FeaturesConfiguration;
  children?: React.ReactNode | React.ReactNode[];
};

export const FeaturesProvider: React.FC<Props> = ({ configuration, children }) => {
  const maybeConfiguration = configuration ?? featuresConfig as FeaturesConfiguration;

  return (
    <FeaturesContext.Provider value={maybeConfiguration}>
      {children}
    </FeaturesContext.Provider>
  );
}
