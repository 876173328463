import { Network } from "@src/lib/Network";

const routes = {
  create: "/api/contracts",
  metadata: (address: string, network: Network) => {
    const params = new URLSearchParams({ address, network });

    return `/api/contracts/metadata?${params}`;
  },
};

export default { contracts: routes };
