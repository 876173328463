import React, { useContext } from "react";
import { NextRouter } from "next/router";

type NavigationState = {
  navigate: (callback: (router: NextRouter) => Promise<void>) => Promise<void>;
  navigateTo: (path: string) => Promise<void>;
};

export const NavigationContext = React.createContext<NavigationState>(undefined);

export const useNavigation = () => {
  const navigationContext = useContext(NavigationContext);

  return navigationContext;
};
