import { Title } from "@components/Typography";
import { Modal as AntModal } from "antd";
import React from "react";
import { ModalProps } from "./Modal.types";


const Modal: React.FunctionComponent<ModalProps> = ({ children, form, onOk, onCancel, title, ...props }) => {

    const handleOk = () => {
        if (form) {
            form.validateFields()
                .then(() => {
                    if (onOk) onOk()
                })
                .catch(() => { })
        } else if (onOk) {
            onOk()
        }
    };

    const handleCancel = () => {
        if (form) form.resetFields()
        if (onCancel) onCancel()
    };

    return (
        <AntModal
            {...props}
            onOk={handleOk}
            onCancel={handleCancel}
            closable={false}
            destroyOnClose
        >
            <div className="w-full h-full flex flex-col gap-3">
                {title ? <Title level={5}>{title}</Title> : null}
                {children}
            </div>
        </AntModal>
    );
};

export default Modal;
