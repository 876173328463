import React, { useCallback, useMemo } from "react";
import { ConfirmContext, ConfirmContextDefinition, ConfirmDialogContextProps, ConfirmDialogProps } from "./useConfirm";

type Props = {
  children?: React.ReactNode | React.ReactNode[];
};

const initialProps: ConfirmDialogContextProps = {
  open: false,
  proceed: null,
  cancel: null,
  extra: null,
  title: "",
  description: "",
  confirmBtnText: "",
  cancelBtnText: "",
  color: "default",
};

export const ConfirmProvider: React.FC<Props> = ({ children }) => {
  const [dialogProps, setDialogProps] = React.useState<ConfirmDialogContextProps>(initialProps);

  const open = (props: Omit<ConfirmDialogContextProps, "open">) => {
    setDialogProps({
      ...props,
      open: true,
    });
  };

  const close = () => {
    setDialogProps({
      ...initialProps,
      open: false,
    });
  };

  const confirm = useCallback((confirmProps: ConfirmDialogProps) => {
    const promise = new Promise((resolve, reject) => {
      open({
        ...confirmProps,
        proceed: resolve,
        cancel: reject,
      });
    });

    return promise.then(
      () => {
        close();
        return true;
      },
      () => {
        close();
        return false;
      },
    );
  }, []);

  const wrapper: ConfirmContextDefinition = useMemo(
    () => ({
      confirm,
      dialogProps,
    }),
    [confirm, dialogProps],
  );

  return <ConfirmContext.Provider value={wrapper}>{children}</ConfirmContext.Provider>;
};
