import { useTranslation } from "react-i18next";
import APIRoutes from "@src/routes/api/routes";
import { WorkspaceRequestResult } from "@src/pages/api/workspaces";
import { getLocalWorkspaceHeaders } from "@features/auth/useAuth";
import { APPLICATION_JSON, CONTENT_TYPE } from "@src/lib/constant/HTTP";

export type WorkspaceApiHooks = {
  getUserWorkspaces: () => Promise<WorkspaceRequestResult>;
  selectWorkspace: (workspaceId: string) => Promise<void>;
  // create: (newWorkspace: string) => Promise<UserWorkspaceInformation>;
};

export const useWorkspaceApi = (): WorkspaceApiHooks => {
  const { t } = useTranslation();

  const getUserWorkspaces = async (): Promise<WorkspaceRequestResult> => {
    const response = await fetch(APIRoutes.workspaces.getUserWorkspaces, {
      headers: getLocalWorkspaceHeaders(),
    });

    if (response.ok) {
      return (await response.json()) as WorkspaceRequestResult;
    }

    if (response.status === 404) {
      return [];
    }

    throw new Error(t("An error occurred and workspaces could not me listed."));
  };

  const selectWorkspace = async (newWorkspace: string): Promise<void> => {
    const response = await fetch(APIRoutes.workspaces.selectUserWorkspace(newWorkspace), {
      method: "POST",
      headers: {
        ...getLocalWorkspaceHeaders(),
        [CONTENT_TYPE]: APPLICATION_JSON,
      },
    });

    if (!response.ok) {
      throw new Error(t("An error occurred and workspace could not be selected."));
    }
  };

  return {
    getUserWorkspaces,
    selectWorkspace,
  };
};
