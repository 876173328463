import ConfirmOverlay from "@components/ConfirmOverlay/ConfirmOverlay";
import { ConfirmProvider } from "@components/ConfirmOverlay/ConfirmProvider";
import { FeaturesProvider } from "@components/Features";
import NavigationProvider from "@src/features/navigation/NavigationProvider";
import React from "react";

type Props = {
  children?: React.ReactNode | React.ReactNode[];
};

export const EngageProviders: React.FC<Props> = ({ children }) => {
  return (
    <NavigationProvider>
      <FeaturesProvider>
        <ConfirmProvider>
          {children}
          <ConfirmOverlay />
        </ConfirmProvider>
      </FeaturesProvider>
    </NavigationProvider>
  );
};
