import React, { ReactNode } from "react";

export interface ConfirmDialogProps {
  title: string;
  description?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  extra?: ReactNode;
  color?: "danger" | "default";
}

export interface ConfirmDialogContextProps extends ConfirmDialogProps {
  open: boolean;
  proceed: any;
  cancel: any;
}

export interface ConfirmContextDefinition {
  confirm: (dialog: ConfirmDialogProps) => Promise<boolean>;
  dialogProps: ConfirmDialogContextProps;
}

export const ConfirmContext = React.createContext<ConfirmContextDefinition>(undefined);
export const useConfirm = () => React.useContext(ConfirmContext);
