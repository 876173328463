import { FormInstance, ModalProps as AntModalProps } from "antd";
import React from "react"

export interface ModalProps extends AntModalProps {
    form?: FormInstance;
    onOk?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onCancel?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface BasicModalProps {
    open: boolean;
    form?: FormInstance;
    title?: string;
    onOk?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onCancel?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}