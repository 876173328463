import { Spin } from "antd";
import React from "react";

const SpinnerPage: React.FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <Spin />
    </div>
  );
};

export default SpinnerPage;
