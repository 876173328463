import { StreamNames } from "@src/features/data-management/activity-stream/types";

const routes = {
  workflows: {
    get: (stream: StreamNames) => `/api/outbound/workflows/get/${stream}`,
    add: `/api/outbound/workflows/add`,
    delete: `/api/outbound/workflows/delete`,
  },
  integrations: {
    list: `/api/outbound/integrations/list`,
    mixpanel: {
      list: `/api/outbound/integrations/mixpanel/list`,
      create: `/api/outbound/integrations/mixpanel/create`,
      remove: `/api/outbound/integrations/mixpanel/remove`,
    },
    crossmint: {
      list: `/api/outbound/integrations/crossmint/list`,
      create: `/api/outbound/integrations/crossmint/create`,
      delete: `/api/outbound/integrations/crossmint/delete`,
    },
    telegram: {
      list: `/api/outbound/integrations/telegram/list`,
      create: `/api/outbound/integrations/telegram/create`,
      delete: `/api/outbound/integrations/telegram/delete`,
    },
    google: {
      list: `/api/outbound/integrations/google/list`,
      create: `/api/outbound/integrations/google/create`,
      delete: `/api/outbound/integrations/google/delete`,
    },
    salesforce: {
      list: `/api/outbound/integrations/salesforce/list`,
      getAuthorizationUrl: `/api/outbound/integrations/salesforce/oauth2/get-authorization-url`,
      delete: `/api/outbound/integrations/salesforce/delete`,
    },
    slack: {
      list: `/api/outbound/integrations/slack/list`,
      create: `/api/outbound/integrations/slack/create`,
      delete: `/api/outbound/integrations/slack/remove`,
    },
    twitter: {
      list: `/api/outbound/integrations/twitter/list`,
      create: `/api/outbound/integrations/twitter/create`,
      delete: `/api/outbound/integrations/twitter/delete`,
    },
    zapier: {
      list: `/api/outbound/integrations/zapier/list`,
      create: `/api/outbound/integrations/zapier/create`,
      delete: `/api/outbound/integrations/zapier/delete`,
    },
    klaviyo: {
      list: "/api/outbound/integrations/klaviyo/list",
      create: "/api/outbound/integrations/klaviyo/create",
      delete: "/api/outbound/integrations/klaviyo/delete",
    },
    sendgrid: {
      list: "/api/outbound/integrations/sendgrid/list",
      create: "/api/outbound/integrations/sendgrid/create",
      delete: "/api/outbound/integrations/sendgrid/remove",
    },
  },
};

export default { outbound: routes };
