const routes = {
  list: "/api/sources/list",
  get: (sourceId: string) => {
    return `/api/sources/${sourceId}`;
  },
  static: {
    csv: {
      get: (sourceId: string) => {
        return `/api/sources/static/csv/get?sourceId=${sourceId}`;
      },
      delete: `/api/sources/static/csv/delete`,
      define: "/api/sources/static/csv/define",
    },
  },
  crossmint: {
    read: `/api/sources/crossmint/list`,
    delete: `/api/sources/crossmint/delete`,
    create: `/api/sources/crossmint/create`,
  },
  segment: {
    read: `/api/sources/segment/get`,
    delete: `/api/sources/segment/delete`,
    create: `/api/sources/segment/add`,
  },
  twitter: {
    list: `/api/sources/twitter/list`,
    delete: `/api/sources/twitter/delete`,
    define: `/api/sources/twitter/define`,
    activate: `/api/sources/twitter/activate`,
    pause: `/api/sources/twitter/pause`,
  },
  zealy: {
    create: "/api/sources/zealy/define",
    read: "/api/sources/zealy/list",
    delete: "/api/sources/zealy/delete",
  },
  salesforce: {
    read: "/api/sources/salesforce/list",
  },
  discord: {
    read: "/api/sources/discord/list",
  },
  alchemy: {
    read: "/api/sources/alchemy/list",
  },
};

export default { sources: routes };
