import React from "react";

export const Code: React.FC<{ children?: React.ReactNode | React.ReactNode[] | undefined; caption?: string }> = ({ children, caption }) => {
  return (
    <div className="flex flex-col">
      <div className="w-1/2 min-w-fit">
        <pre className="text-xs text-left items-center gap-x-4 bg-gray-100 text-gray-600 rounded-lg p-4 pl-6">{children}</pre>
      </div>
      <small className="text-sm  text-gray-600 ">{caption}</small>
    </div>
  );
};
