import React, { useEffect, useMemo } from "react";
import { Amplify } from "aws-amplify";
import { Hub } from 'aws-amplify/utils';
import { AwsAmplifyConfig, cookieStorageConfig } from "@src/aws-exports";
import {
  getCurrentUser,
  signIn as amplifySignIn,
  signInWithRedirect as amplifySignInWithRedirect,
  type SignInInput,
  signOut as amplifySignOut,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import DisplayIf from "@components/Conditionals/DisplayIf";
import SpinnerFullPage from "@components/spinners/SpinnerFullPage";
import { AuthContext, AuthContextProps, LOCALSTORAGE_WORKSPACE_KEY } from "@src/features/auth/useAuth";
import { cognitoUserToUser } from "@src/features/auth/utils";
import { EngageUser } from "@src/interfaces/user";
import { browserLogger as logger } from "@src/services/logger";
import { queryClient } from "@src/services/react-query";
import { notify } from "@src/services/toaster";
import { useRouter } from "next/router";
import { useWorkspaceApi } from "@features/workspaces/hooks/useWorkspaceApi";

interface AuthProviderProps {
  children: React.ReactNode | React.ReactNode[] | undefined;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const router = useRouter();
  const { getUserWorkspaces, selectWorkspace } = useWorkspaceApi();

  const [user, setUser] = React.useState<EngageUser>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthSettingUp, setIsAuthSettingUp] = React.useState(false);
  const [hubInitialized, setHubInitialized] = React.useState(false);
  const [workspaceId, setWorkspaceId] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    if (isAuthSettingUp) {
      const unsubscribe = Hub.listen("auth", async ({ payload }) => {
        switch (payload.event) {
          case "signedIn":
            logger.debug(payload, `AuthProvider(hub): signedIn event`);
            if (payload.data) {
              const maybeUser = await cognitoUserToUser({ userId: payload.data?.userId, username: payload.data?.username });
              if (!maybeUser) {
                break;
              }
              setUser(maybeUser);
              let maybeWorkspaceId = localStorage.getItem(LOCALSTORAGE_WORKSPACE_KEY);
              if (!maybeWorkspaceId || maybeWorkspaceId === "") {
                const maybeWorkspaces = await getUserWorkspaces();
                if (maybeWorkspaces.length) {
                  maybeWorkspaceId = maybeWorkspaces[0].id;
                  localStorage.setItem(LOCALSTORAGE_WORKSPACE_KEY, maybeWorkspaceId);
                }
              }
              setWorkspaceId(maybeWorkspaceId);
            }
            break;
          case 'signInWithRedirect':
            logger.debug(payload, `AuthProvider(hub): signInWithRedirect`);
            break;
          case "signedOut":
            logger.debug(`AuthProvider(hub): signout event`);
            setUser(undefined);
            setWorkspaceId(undefined);
            localStorage.removeItem(LOCALSTORAGE_WORKSPACE_KEY);
            break;
          case 'tokenRefresh_failure':
            logger.error(payload, `Could not sign in user, please try again later or contact support.`);
            setUser(undefined);
            setWorkspaceId(undefined);
            break;
          case 'signInWithRedirect_failure':
            setUser(undefined);
            setWorkspaceId(undefined);
            break;
          case 'tokenRefresh':
          default:
            logger.debug(payload, `AuthProvider(hub): Got event [ ${payload.event} ].`);
        }
      });

      setHubInitialized(true);

      Amplify.configure(AwsAmplifyConfig, { 
        ssr: true, 
        Storage: {
          S3: {
            prefixResolver: async ({ accessLevel, targetIdentityId }) => {
              const maybeWorkspaceId = localStorage.getItem(LOCALSTORAGE_WORKSPACE_KEY);
              const targetId = maybeWorkspaceId && maybeWorkspaceId !== "" ? maybeWorkspaceId : targetIdentityId;
              switch (accessLevel) {
                case 'guest':
                  return 'public/';
                case 'protected':
                  return `protected/${targetId}/`;
                case 'private':
                  return `private/${targetId}/`;
                default:
                  throw new Error(`Unsupported access level [ ${accessLevel} ]`);
              }
            }
          }
        }
      });
      cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorageConfig());

      return unsubscribe;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthSettingUp]);

  useEffect(() => {
    const load = async () => {
      try {
        logger.trace(`AuthProvider(useEffect): Will call currentAuthenticatedUser`);
        const userData = await getCurrentUser();
        logger.trace(`AuthProvider(useEffect): userData fetched is: ${JSON.stringify(userData)}`);
        const parsedUser = await cognitoUserToUser({ userId: userData.userId, username: userData.username });
        logger.trace(`AuthProvider(useEffect): parsedUser is: ${JSON.stringify(parsedUser)}`);
        let maybeWorkspaceId = localStorage.getItem(LOCALSTORAGE_WORKSPACE_KEY);

        if (!maybeWorkspaceId || maybeWorkspaceId === "" || maybeWorkspaceId === "undefined" || maybeWorkspaceId === "null") {
          const maybeWorkspaces = await getUserWorkspaces();
          maybeWorkspaceId = maybeWorkspaces?.shift()?.id;
          localStorage.setItem(LOCALSTORAGE_WORKSPACE_KEY, maybeWorkspaceId);
        }

        setWorkspaceId(maybeWorkspaceId);
        await setUser(parsedUser);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err !== "The user is not authenticated") {
          logger.error(`AuthProvider(useEffect): Caught the following error: ${JSON.stringify(err)}`);
        }
      } finally {
        setIsLoading(false);
      }
    };

    logger.trace(`AuthProvider(useEffect): hubInitialied [ ${hubInitialized} ] and user [ ${user} ]`);
    if (hubInitialized && !user) {
      // Check if user is already logged in on mount
      logger.trace(`AuthProvider(useEffect): Will load user data.`);
      load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubInitialized]);

  useEffect(() => {
    if (isLoading && !isAuthSettingUp) {
      setIsAuthSettingUp(true);
    }
  }, [isLoading, isAuthSettingUp]);

  // check if user is authenticated on page load
  const signIn = async (input: { email: string; password: string }) => {
    try {
      const signInInput: SignInInput = {
        username: input.email,
        password: input.password,
        options: {
          authFlowType: 'USER_SRP_AUTH',
        },
      };

      if (!user) {
        setUser({
          userId: input.email,
          username: input.email,
        });
      }

      const signInResult = await amplifySignIn(signInInput);

      if (!signInResult.isSignedIn) {
        throw new Error("Could not sign in.");
      }
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  const signInWihGoogle = async () => {
    try {
      await amplifySignInWithRedirect({
        provider: 'Google',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.debug(err, `signInWihGoogle: Error signing in with Google.`);
      throw err;
    }
  };

  const signOut = async () => {
    try {
      // invalidate all the queries
      await queryClient.invalidateQueries();

      setUser(undefined);

      await amplifySignOut({
        global: true,
      });
      // redirect to login page
      router.push("/login");
    } catch (error) {
      logger.error(error);
      notify.error(error.message);
    }
  };

  // eslint-disable-next-line no-shadow
  const setUserWorkspace = async (workspaceId: string): Promise<void> => {
    await selectWorkspace(workspaceId);
    localStorage.setItem(LOCALSTORAGE_WORKSPACE_KEY, workspaceId);
  };

  const wrapper: AuthContextProps = useMemo(
    () => ({
      user,
      isLoading,
      isAuthenticated: !!user,
      signIn,
      signInWihGoogle,
      signOut,
      setUserWorkspace,
      workspaceId,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, isLoading, workspaceId],
  );

  return (
    <AuthContext.Provider value={wrapper}>
      <DisplayIf condition={() => !isLoading} falsy={<SpinnerFullPage />}>
        {children}
      </DisplayIf>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
