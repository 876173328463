const features = {
    "synced_contracts": {
    "default": "on"
  },
  "workflows": {
    "default": "on"
  },
  "event_sourcing": {
    "default": "on"
  },
  "identity_settings": {
    "default": "on"
  },
  "identity_stream": {
    "default": "on"
  },
  "unique_identity_settings": {
    "default": "on"
  },
  "unique_identity_stream": {
    "default": "on"
  },
  "inbound_settings": {
    "default": "on"
  },
  "outbound_settings": {
    "default": "on"
  },
  "activity_stream": {
    "default": "on"
  },
  "home_page_v2": {
    "default": "on"
  },
  "mixpanel_outbound": {
    "default": "on"
  },
  "system_settings": {
    "default": "on"
  }
}

export default features;
