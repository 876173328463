import React, { useContext } from "react";
import { Condition, ConditionRule, Features, FeaturesConfiguration } from "@components/Features/types";

export const FeaturesContext = React.createContext<FeaturesConfiguration>(undefined);

export interface IUseFeatures {
  isConditionSame: (feature: Features, condition?: ConditionRule) => boolean;
}

export const useFeatures = (): IUseFeatures => {
  const configuration = useContext(FeaturesContext);

  /**
   * @param feature the feature's name, or enum value
   * @param condition in the configuration, the key can be anything, from a username, a workspaceId, etc.
   * @description You can use this to check if a condition is set to On for the given condition(s) & feature.
   * If you have more than one condition defined, they are currently checked sequentially, and if one is configured
   * it will return the first configured condition it encounters.
   */
  const isConditionSame = (feature: Features, condition?: ConditionRule) => {
    const isFeatureConfigured = configuration[feature];

    if (isFeatureConfigured && condition) {
      // could have multiple conditions, loop one after the other, currently they are independent conditions
      for (const c of Object.keys(condition)) {
        const conditionValue = condition[c] ?? Condition.On;

        // if your condition is configured, check if it fits the requirements.
        if (isFeatureConfigured[c]) {
          return isFeatureConfigured[c].toLowerCase() === conditionValue.toLowerCase();
        }
      }

      // if your condition is not configured, fallback on the default.
      return isFeatureConfigured.default.toLowerCase() === Condition.On.toLowerCase();
    }

    if (isFeatureConfigured && !condition) {
      return isFeatureConfigured.default.toLowerCase() === Condition.On.toLowerCase();
    }

    return false;
  };

  return {
    isConditionSame,
  };
};
