import { EngageUser } from "@src/interfaces/user";
import { logger } from "@src/services/logger";
import { fetchAuthSession } from "aws-amplify/auth";

export const cognitoUserToUser = async (input: { userId: string; username: string }): Promise<EngageUser | undefined> => {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const userObj: EngageUser = {
      userId: input.username,
      username: input.username,
    };

    if (!idToken) {
      return userObj;
    }

    logger.debug(idToken, `cognitoUserToUser: found idToken.`);

    userObj.userId = idToken.payload["cognito:username"]?.toString() ?? input.userId;
    userObj.username = idToken.payload.email?.toString() ?? input.username;
    userObj.email = idToken.payload.email?.toString() ?? input.username;
    userObj.name = idToken.payload.name?.toString() || userObj.email;
    userObj.picture = idToken.payload.picture?.toString();

    return userObj;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    logger.debug(err, `cognitoUserToUser: User was not logged in.`);
    return undefined;
  }
};
