import accountRoutes from "@src/routes/api/accountRecovery/routes";
import authRoutes from "@src/routes/api/auth/routes";
import invitesRoutes from "@src/routes/api/invites/routes";
import organizationsRoutes from "@src/routes/api/organizations/routes";
import workspacesRoutes from "@src/routes/api/workspaces/routes";
import contractsRoutes from "@src/routes/api/contracts/routes";
import activitiesRoutes from "@src/routes/api/activities/routes";
import syncedContractsRoutes from "@src/routes/api/synced-contracts/routes";
import fidesRoutes from "@src/routes/api/fide/routes";
import outboundRoutes from "@src/routes/api/outbound/routes";
import sourcesRoutes from "@src/routes/api/sources/routes";
import destinationsRoutes from "@src/routes/api/destinations/routes";
import identityRoutes from "@src/routes/api/identities/routes";
import uniqueIdentityRoutes from "@src/routes/api/unique-identities/routes";
import flipsideRoutes from "@src/routes/api/flipside/routes";
import systemRoutes from "@src/routes/api/system/routes";

const APIRoutes = {
  ...accountRoutes,
  ...authRoutes,
  ...contractsRoutes,
  ...invitesRoutes,
  ...organizationsRoutes,
  ...workspacesRoutes,
  ...activitiesRoutes,
  ...syncedContractsRoutes,
  ...fidesRoutes,
  ...outboundRoutes,
  ...sourcesRoutes,
  ...destinationsRoutes,
  ...identityRoutes,
  ...uniqueIdentityRoutes,
  ...flipsideRoutes,
  ...systemRoutes,
};

export default APIRoutes;
