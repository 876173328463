import React from "react";
import { Condition, Features } from "@components/Features/types";
import { useFeatures } from "@components/Features/contexts";
import DisplayIf from "@components/Conditionals/DisplayIf";
import { useAuth } from "@features/auth/useAuth";

type Props = {
  feature: Features;
  conditionKeys?: string[];
  children?: React.ReactNode | React.ReactNode[];
  falsy?: React.ReactNode | React.ReactNode[];
};

/**
 * @param feature the feature's name, or enum value
 * @param conditionKeys in the configuration, the key can be anything, from a username, a workspaceId, etc.
 * @param children the elements to show if the feature's condition are met
 * @param falsy the elements to show if the feature's condition is NOT meet
 * @description This component uses the user state, and injects automatically the org and workspace id as conditions
 * in the feature flags evaluation.
 * You can use this to check if a condition is set to On for the given condition Key & feature. For example:
 *
 *     <IsFeatureEnabled feature={Features.Web3FormsEmbedSharing} falsy={<span>nope</span>}>
 *       <ViewWeb3FForm id={formId} twitter={twitter} discord={discord} />
 *     </IsFeatureEnabled>
 *
 *     example with a condition key:
 *
 *     <IsFeatureEnabled feature={Features.StudioFeature} conditionKey={user.workspaceId}>
 *         <PictureOutlined /> <Text>{t("Generative")}</Text>
 *      </IsFeatureEnabled>
 */
export const IsFeatureEnabledForUser: React.FC<Props> = ({ feature, conditionKeys, children, falsy }) => {
  const { isConditionSame } = useFeatures();
  const { user, workspaceId } = useAuth();
  const conditions = {};
  if (conditionKeys) {
    for (const c of conditionKeys) {
      conditions[c] = Condition.On;
    }
  }

  if (user && workspaceId) {
    conditions[workspaceId] = Condition.On;
    // conditions[maybeUser.orgId] = Condition.On;
  }

  const results = Object.keys(conditions).length > 0 ? isConditionSame(feature, conditions) : isConditionSame(feature);

  return (
    <DisplayIf condition={() => results} falsy={falsy}>
      {children}
    </DisplayIf>
  );
};
