import { EngageUser } from "@src/interfaces/user";
import React from "react";

export interface AuthContextProps {
  user: EngageUser;
  isLoading: boolean;
  isAuthenticated: boolean;
  signIn: (input: { email: string; password: string }) => Promise<void>;
  signInWihGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  setUserWorkspace: (workspaceId: string) => Promise<void>;
  workspaceId?: string;
}

export const LOCALSTORAGE_WORKSPACE_KEY = "engage_admin_wk";
export const WORKSPACE_HEADER = "x-workspace-id";

export const getLocalWorkspaceId = (): string => localStorage.getItem(LOCALSTORAGE_WORKSPACE_KEY);
export const getLocalWorkspaceHeaders = (workspaceId?: string) => ({
  [WORKSPACE_HEADER]: workspaceId ?? getLocalWorkspaceId(),
});

export const AuthContext = React.createContext<AuthContextProps>(undefined!);
export const useAuth = () => React.useContext(AuthContext);
