export enum Features {
  Web3FormsEmbedSharing = "web3forms_embed_sharing",
  StudioDownloadAsset = "studio_download_asset",
  StudioFeature = "studio_feature",
  MintingEmbed = "minting_embed",
  StudioDeployPlugins = "studio_deploy_plugins",
  StudioContractAccessList = "studio_contract_accesslist",
  StudioVersioning = "studio_versioning",
  StudioAirdrop = "studio_airdrop",
  SyncedContracts = "synced_contracts",
  Workflow = "workflows",
  EventSourcing = "event_sourcing",
  IdentitySettings = "identity_settings",
  UniqueIdentitySettings = "unique_identity_settings",
  InboundSettings = "inbound_settings",
  OutboundSettings = "outbound_settings",
  ActivityStream = "activity_stream",
  IdentityStream = "identity_stream",
  UniqueIdentityStream = "unique_identity_stream",
  HomePageV2 = "home_page_v2",
  SystemSettings = "system_settings",
}

export enum Condition {
  On = "on",
  Off = "off",
}

export type ConditionRule = {
  [criteria: string]: Condition;
};

export type FeatureCondition = {
  default: Condition;
} & ConditionRule;

export type FeaturesConfiguration = {
  [feature: string]: FeatureCondition;
};
