import React from "react";
import { Typography as AntTypography, TypographyProps } from "antd";
import { TitleProps as AntTitleProps } from "antd/lib/typography/Title";
import { TextProps } from "antd/lib/typography/Text";
import { ParagraphProps } from "antd/lib/typography/Paragraph";
import { TitleProps } from "./Typography.types";

const { Title: AntTitle, Text: AntText, Paragraph: AntParagraph } = AntTypography;

const Typography: React.FC<TypographyProps> = (props) => {
  return <AntTypography {...(props as any)} />;
};

export const Title: React.FC<AntTitleProps> = ({ style, ...props }) => {
  return <AntTitle {...props} style={{ ...style, margin: 0 }} />;
};

export const Text: React.FC<TextProps> = ({ style, ...props }) => {
  return <AntText {...props} style={{ ...style, margin: 0 }} />;
};

export const Paragraph: React.FC<ParagraphProps> = ({ style, ...props }) => {
  return <AntParagraph {...props} style={{ ...style, margin: 0 }} />;
};

export const PageTitle: React.FC<TitleProps> = ({ style, ...props }) => {
  return <Title level={3} {...props} style={{ ...style, margin: 0 }} />;
};

export const PageSubTitle: React.FC<TitleProps> = ({ style, ...props }) => {
  return <Title level={5} {...props} style={{ ...style, margin: 0, fontWeight: "normal" }} />;
};

export default Typography;
