const routes = {
  create: "/api/workspaces/create",
  join: "/api/workspaces/join",
  getUserWorkspaces: "/api/workspaces",
  stream: "/api/workspaces/stream",
  selectUserWorkspace: (workspaceId: string) => `/api/workspaces/select?workspaceId=${workspaceId}`,
  apiKeys: {
    create: "/api/workspaces/api-key",
    read: (type: "Public" | "Secret") => `/api/workspaces/api-key?type=${type}`,
    remove: "/api/workspaces/api-key",
    v2: {
      create: "/api/workspaces/api-key/v2",
      read: (type: "Public" | "Secret") => `/api/workspaces/api-key/v2?type=${type}`,
      remove: "/api/workspaces/api-key/v2",
    },
  },
  workflows: {
    list: "/api/workspaces/workflows",
    start: (workflowId: string) => {
      const urlParams = new URLSearchParams({ workflowId });
      return `/api/workspaces/workflows/start/?${urlParams.toString()}`;
    },
    pause: (workflowId: string) => {
      const urlParams = new URLSearchParams({ workflowId });
      return `/api/workspaces/workflows/pause/?${urlParams.toString()}`;
    },
    delete: (workflowId: string) => {
      const urlParams = new URLSearchParams({ workflowId });
      return `/api/workspaces/workflows/delete/?${urlParams.toString()}`;
    },
    define: "/api/workspaces/workflows/define",
    get: (workflowId: string) => {
      const urlParams = new URLSearchParams({ workflowId });
      return `/api/workspaces/workflows/definition?${urlParams.toString()}`;
    },
  },
  identities: {
    list: "/api/workspaces/settings/identities",
    create: `/api/workspaces/settings/identities/create`,
    delete: `/api/workspaces/settings/identities/delete`,
  },
  destinations: {
    webhooks: {
      create: `/api/destinations/webhooks/create`,
      delete: `/api/destinations/webhooks/delete`,
    },
    list: "/api/destinations/list",
  },
  uniqueIdentities: {
    list: "/api/workspaces/settings/unique-identities",
    create: `/api/workspaces/settings/unique-identities/create`,
    delete: `/api/workspaces/settings/unique-identities/delete`,
  },
  webhooks: {
    list: "/api/workspaces/settings/webhooks",
    create: `/api/workspaces/settings/webhooks/create`,
    delete: `/api/workspaces/settings/webhooks/delete`,
  },
  system: {
    list: "/api/workspaces/settings/system",
    workspace: (workspaceId: string) => `/api/workspaces/settings/system/details?workspaceId=${workspaceId}`,
  },
};

export default { workspaces: routes };
