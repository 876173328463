import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@src/services/redux/store.types';
import { ITableReducer } from "./table.types";

// Define the initial state using that type
const initialState: ITableReducer = {
    position: undefined,
    row: undefined
}

const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        onContextMenu: (state, action: PayloadAction<{ position: { clientX: number, clientY: number }, row: unknown }>) => {
            return {
                ...state,
                position: action.payload.position,
                row: action.payload.row
            }
        },
        closeContextMenu: (state) => {
            return {
                ...state,
                position: undefined,
                row: undefined
            }
        }
    },
});

export const {
    onContextMenu
} = tableSlice.actions;

export const tableReducer = tableSlice.reducer
export const reduxTableActions = tableSlice.actions
export const selectTable = (state: RootState) =>
    state.table

