export const allColors = ["red", "green", "blue", "grey"] as const;

export type ColorType = typeof allColors[number];

export const PRIMARY_COLOR = "#0033FF";
export const SECONDARY_COLOR = "#001529";
export const DANGER_COLOR = "#ff4d4f";
export const SUCCESS_COLOR = "#52c41a";
export const DARK_TEXT_GREY_COLOR = "#595959";
export const LIGHT_TEXT_GREY_COLOR = "#88898b";
