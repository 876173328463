import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { navigationReducer } from "./reducers/navigation/navigation.reducer";
import { tableReducer } from "./reducers/table/table.reducer";
import { RootState } from "./store.types";

export const reduxStore = configureStore({
  reducer: {
    table: tableReducer,
    navigation: navigationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["auth/updateAttributes"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["payload.updateAttributes"],
        // Ignore these paths in the state
        ignoredPaths: ["auth.updateAttributes"],
      },
    }),
});

export type AppDispatch = typeof reduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
