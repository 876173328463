import Modal from "@components/modals/Modal";
import { Typography } from "antd";
import React from "react";
import { useConfirm } from "./useConfirm";

const { Text } = Typography;

const ConfirmOverlay: React.FC = () => {
  const { dialogProps } = useConfirm();
  const { open, title, description, confirmBtnText, cancelBtnText, color, proceed, cancel, extra } = dialogProps;

  return (
    <Modal open={open} title={title} okText={confirmBtnText} cancelText={cancelBtnText} okButtonProps={{ danger: color === "danger" }} onOk={proceed} onCancel={cancel}>
      <div className="flex flex-col gap-2">
        <Text>{description}</Text>
        {extra}
      </div>
    </Modal>
  );
};

export default ConfirmOverlay;
