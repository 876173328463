import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@src/services/redux/store.types';
import { INavigationReducer } from "./navigation.types";

// Define the initial state using that type
const initialState: INavigationReducer = {
    open: true
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            return {
                ...state,
                open: !state.open
            }
        },

    },
});

export const navigationReducer = navigationSlice.reducer
export const navigationActions = navigationSlice.actions
export const selectNavigation = (state: RootState) =>
    state.navigation

