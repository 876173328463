import SpinnerPage from "@components/spinners/SpinnerPage";
import "@services/dayjs";
import "@src/css/tailwind.css";
import "antd/dist/antd.variable.min.css";
import AuthProvider from "@src/features/auth/AuthProvider";
import "@src/index.css";
import "@components/engage/tables/tables.css";
import "@components/engage/flippy/flippable.css";
import { queryClient } from "@src/services/react-query";
import { reduxStore } from "@src/services/redux";
import { PRIMARY_COLOR } from "@src/services/theme/colors";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { AppProps } from "next/app";
import Head from "next/head";
import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { EngageProviders } from "@components/Providers/EngageProviders";

ConfigProvider.config({
  theme: {
    primaryColor: PRIMARY_COLOR,
  },
});

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: "en",
    ns: ["common"],
    defaultNS: "common",
    lng: "en",
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: true,
    },
  });

const Main: React.FunctionComponent<any> = ({ pageProps, Component }) => {
  // const [initialRenderComplete, setInitialRenderComplete] = useState<boolean>(false);
  //
  // useEffect(() => {
  //   if (process.env.ENV === "prod")
  //     hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID as unknown as number, process.env.NEXT_PUBLIC_HOTJAR_VERSION as unknown as number);
  //   setInitialRenderComplete(true);
  // }, []);
  //
  // if (!initialRenderComplete) return <SpinnerPage />;

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0">
      <ConfigProvider>
        <EngageProviders>
          <Component {...pageProps} />
        </EngageProviders>
      </ConfigProvider>
    </div>
  );
};

const MyApp: React.FunctionComponent<AppProps> = ({ Component, pageProps }) => (
  <Suspense fallback={<SpinnerPage />}>
    <QueryClientProvider client={queryClient}>
      <Provider store={reduxStore}>
        <AuthProvider>
          <Head>
            <title>Metacommerce</title>
            <meta name="title" content="Metacommerce" />
            <meta name="description" content="Metacommerce - Deploy your smart contracts and launch a storefront today." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://metacommerce.app/" />
            <meta property="og:title" content="Metacommerce" />
            <meta property="og:description" content="Metacommerce - Deploy your smart contracts and launch a storefront today." />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content="Metacommerce" />
            <meta name="robots" content="noindex,nofollow" />
            <meta property="twitter:description" content="Metacommerce - Deploy your smart contracts and launch a storefront today." />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
          </Head>
          <Main Component={Component} pageProps={pageProps} />
          <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
        </AuthProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Suspense>
);

export default MyApp as any;
